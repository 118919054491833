.cursor {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.justifycontent {
  text-align: justify;
  line-height: 1.8;
}

/* scroll css start here */
html {
  scroll-behavior: smooth;
}

body {
  position: relative;
}

.scrolltop-wrap {
  box-sizing: border-box;
  position: absolute;
  top: 12rem;
  right: 2rem;
  bottom: 0;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.scrolltop-wrap a {
  position: fixed;
  position: sticky;
  top: -5rem;
  width: 3rem;
  height: 3rem;
  margin-bottom: -5rem;
  transform: translateY(100vh);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: inline-block;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: all;
  outline: none;
  overflow: hidden;
}

/* scroll css ends here */

.Banner-Bg {
  background-image: url("./components/assets/Banner/732220.jpg");
  background-color: #cccccc;
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.CoverOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.nav-link {
  color: #626160 !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #65e4fd !important;
  text-decoration: underline;
}

.btn-primary {
  background-color: #1ea3ae !important;
  border-color: #1ea3ae !important;
  border-radius: 19px !important;
}

.btn-outline-info {
  color: #1ea3af !important;
  border-color: #1ea3af !important;
  border-radius: 20px !important;
  border: 2px solid #1ea3af !important;
}

.btn-outline-secondary {
  border-radius: 20px !important;
  width: 30% !important;
}

.btn-outline-info:hover {
  color: #fff !important;
}

.Custombtn {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.ParaHeight {
  height: calc(100% - 1.25rem);
  border-radius: 12px;
}

.cardhover:hover {
  background-color: #097856;
  color: #fff !important;
  text-decoration: none;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

.cardhover {
  text-decoration: none !important;
  color: #000 !important;
}

.nav-menu:hover {
  color: #4ac7f2 !important;
}

.text-info {
  color: #4ac7f2 !important;
}

.modal-body {
  color: #1b1b1b;
  border-top: 1px solid #e4e7eb;
  border-bottom: 1px solid #e4e7eb;
  margin: 10px 0px;
}

.FormControl {
  border-radius: 12px !important;
}

.FormSelect {
  border-radius: 12px !important;
}

.modal-header {
  color: #5c5b57;
}

.EmarketCard {
  height: calc(100% - 1.25rem);
  border-radius: 12px !important;
}

.EmarketCard .card-body {
  padding: 1rem 1rem 2rem;
}

.EmarketCard .card-text {
  height: 145px;
}

.MarkerPoint {
  list-style-image: url("./components/assets/play.png");
  cursor: pointer;
  margin-right: 1.5rem;
}


.AccordionItem {
  border: none !important;
  border-radius: 15px !important;
}

.accordion-button {
  background-color: #f8f9fa !important;
  border-radius: 12px !important;
  border: 1px solid #65e4fd !important;
}

.accordion-button:not(.collapsed) {
  color: #16152d !important;
}

.AccordionItem:hover {
  box-shadow: 2px 1px 8px #f8f9fa;
}

.AccordionItem button {
  background: red;
  border-radius: 12px;
  padding: 26px 20px 26px 40px;
  position: relative;
  display: flex;
  align-items: center;
}

.AccordionItem button::before {
  content: " ";
  left: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-width: 30px 0 30px 25px;
  border-color: transparent transparent transparent rgb(101, 228, 253);
  border-style: solid;
}

.BgCustom {
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 20px !important;
}

.CardCustom {
  border-radius: 12px !important;
}

.CardCustom:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.SocialMedia {
  color: #232a32;
  font-size: 20px;
}

.SocialMedia:hover {
  color: blue;
}

.StatisticsBg {
  background-color: #1ea3ae;
  background-image: url("./components/assets/Statistics-bg.jpg");
  height: 400px;
  background-position: 0% 30%;
  background-repeat: no-repeat;
  background-size: cover;
}

.StatisticsBg .container {
  margin-top: 280px;
}

.DataBg {
  background-color: #f8f9fa;
  color: #111111;
  border-radius: 20px !important;
}

.Content-static {
  background-color: #f8f9fa;
}

.contentMargin {
  padding-top: 71px;
}

.SocialIcons a {
  color: #1ea3ae;
  font-size: 19px;
}

.SocialIcons a:hover {
  color: #1ea3ae;
  font-size: 19px;
  transform: translateY(2px);
  transition-duration: 0.3s;
  transition-property: transform;
}

.StaticCard-section {
  border-radius: 13% !important;
  width: 130px;
  height: 130px;
  align-items: center;
  margin: auto;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 0px 0px 2px #1ea3ae;
}

.StaticCard-section img {
  height: 130px;
}

.activeTab {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px solid #1ea3ae !important;
}

.activeTab div {
  color: #1ea3ae;
}

.Circle2 {
  margin-top: -103px !important;
}

.Circle3 {
  margin-top: 77px;
}

.SocialMediaFooter a {
  border: 2px solid #1ea3ae;
  border-radius: 50%;
  color: #37adb7;
}

.SocialMediaFooter a:hover {
  transform: translateY(2px);
  transition-duration: 0.3s;
  transition-property: transform;
}

.SocialMediaFooter span:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #37adb7;
}

.CustomColor {
  color: #1ea3ae;
}

.numbercustom {
  padding: 10px 12px;
  border: 2px solid #1ea3ae;
  border-radius: 50%;
}

.CustomBgColor {
  background-color: #1ea3ae;
}

.IconAnimation:hover {
  transform: translateY(2px);
  transition-duration: 0.3s;
  transition-property: transform;
}

.borderinfo {
  border-color: #1ea3ae !important;
}

.borderStart {
  border-left: 1px solid #1ea3ae !important;
}

.ContactUsImg img {
  margin-left: -376px;
}

.Banner2-Bg {
  background-image: url("./components/assets/about-us/banner-img1.jpg");
  background-color: #cccccc;
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.CoverOverlay2 {
  background-color: rgba(0, 0, 0, 0.8);
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Banner3-Bg {
  background-image: url("./components/assets/Export\ Invoice\ Discounting/banner.jpeg");
  background-color: #cccccc;
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Banner4-Bg {
  background-image: url("./components/assets/supply\ chain/banner-bg.jpeg");
  background-color: #cccccc;
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Banner5-Bg {
  background-image: url("./components/assets/export\ lc\ discounting/banner.jpg");
  background-color: #cccccc;
  height: 650px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.ImageStyle img {
  filter: grayscale();
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 6px solid whitesmoke;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
  transition: filter 0.4s ease-in-out;
}

.ImageStyle:hover img {
  filter: none;
  border: 6px solid #1ea3ae;
}

.title {
  position: relative;
}

.title::after {
  position: absolute;
  content: "";
  width: 0%;
  height: 4px;
  background-color: whitesmoke;
  left: 50%;
  bottom: -10px;
  transition: all 0.4s ease-in-out;
}

.weserve-section {
  border-radius: 50% !important;
  width: 130px;
  height: 130px;
  align-items: center;
  margin: 12px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 0px 0px 7px #1ea3ae;
}

.ImageVision {
  background-image: url(./components/assets/about-us/vision.jpg);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.OverlayVision {
  background-color: rgba(0, 0, 0, 0.5);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Contactusbg {
  background-image: url(./components/assets/Contact-us/contact.webp);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.CardsFlex {
  display: block;
}

.ImproveLiq img {
  height: 450px;
}

.TeamLeft {
  border-radius: 30px;
}

.TeamLeft img {
  border-radius: 12px;
  height: 250px;
  width: 250px;
}

.TeamCard {
  border-radius: 30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.ecard {
  border-radius: 12px !important;
}

.ecard:hover {
  filter: drop-shadow(-2px 4px 8px rgba(20, 118, 163, 0.233));
  background-color: #1ea3ae;
  color: #fff;
}
.ecard:hover .card-footer a{
  color: #fff !important;
}
.marketplaceparaheight {
  height: 140px;
}

.navheight {
  height: 400px;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.navheight::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.BorderLeft {
  border-left: 4px solid #f8f9fa;
  padding: 8px;
}

.active.BorderLeft {
  border-left: 4px solid #1ea3ae;
  padding: 8px;
}

.Banner6-Bg {
  background-image: url("./components/assets/casestudies/banner.png");
  background-color: #cccccc;
  height: 450px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.CoverOverlay3 {
  background-color: rgba(0, 0, 0, 0.5);
  background-position: center;
  height: 450px;
  background-repeat: no-repeat;
  background-size: cover;
}

.TextJustify {
  text-align: justify;
}

.caseStudies {
  margin-left: 6% !important;
}

.caseStudyImg img {
  height: 400px;
}

.staticcard img {
  height: 120px;
}

.Banneronboarding-Bg {
  background-image: url("./components/assets/onboarding/banner-2.jpeg");
  background-color: #cccccc;
  height: 530px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.CoverOverlay6 {
  background-color: rgba(0, 0, 0, 0.7);
  background-position: center;
  height: 530px;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-check-input:checked {
  background-color: #1ea3ae;
  border-color: #1ea3ae;
}

.display {
  display: none;
}

.videoimg {
  height: 390px !important;
  width: 500px !important;
}

.processcard:hover {
  filter: drop-shadow(-2px 4px 8px rgba(20, 118, 163, 0.233));
}

.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.67);
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay span {
  width: 80px;
  height: 80px;
}

.loading-overlay span img {
  width: 100%;
}

.dashboard-features img {
  height: 550px;
  width: 310px;
}

.blogcard {
  border-radius: 30px !important;
  transition: transform 0.2s ease;
}

.blogcard:hover {
  transform: translateY(-4px);
}

.Latestcard {
  border-radius: 30px;
  height: 350px;
  padding-top: 148px !important;
}

.sharedropdown {
  margin-left: -100px !important;
  margin-top: 10px !important;
}

.sharedropdown::after {
  position: absolute;
  left: 58%;
  top: -8px;
  width: 0;
  height: 0;
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ffffff;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff !important;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #1ea3ae !important;
  border-color: #1ea3ae !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #1ea3ae !important;
}

.Imageheight img {
  height: 230px;
}

.buttonpostion {
  z-index: 99;
  margin-left: 16%;
}

.cardspace p,
h6 {
  margin: 20px;
  margin-bottom: 0px;
}

.readmorep {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 6.0em;
  line-height: 1.5em;
}

.at-work-process {
  position: relative;
  z-index: 4;
  overflow: hidden;
  text-align: center;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.at-work-process-text {
  line-height: 40px;
  transition: all 200ms linear 0s;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.at-work-process:hover .at-work-process-text {
  -webkit-transform: translateY(-150%);
  -moz-transform: translateY(-150%);
  -ms-transform: translateY(-150%);
  -o-transform: translateY(-150%);
  transform: translateY(-150%);
}

.at-work-process:hover .at-work-process-details {
  z-index: 2;
  opacity: 1;
  bottom: 0;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  transition: all 200ms linear;
}

.at-work-process-number {
  color: #1ea3ae;
  display: block;
}

.at-work-process-number-text {
  /* font-size: 13px; */
  font-weight: 600;
  color: #000000;
  letter-spacing: 2px;
}

.at-separator-thick {
  background: #d4d4d4;
  margin: 3% auto;
  height: 4px;
  width: 30px;
}

.at-work-process-details {
  bottom: -60px;
  height: 100%;
  left: 0;
  opacity: 0;
  width: 100%;
  position: absolute;
  display: block;
}
.at-work-process-details i {
  color: #1ea3ae;
  margin-bottom: 15px;
  display: block;
  font-size: 40px;
}
.at-work-process-details span {
  font-size: 11px;
  line-height: 15px;
}

.item {
  background: #fff;
  text-align: center;
  padding: 30px 25px;
  -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  border: 5px solid rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item:hover {
  background: #1ea3ae;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item:hover .item,
.item:hover span.icon {
  background: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item:hover h5,
.item:hover p {
  color: #fff;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}
.item .icon {
  font-size: 40px;
  margin-bottom: 25px;
  color: #1ea3ae;
  width: 90px;
  height: 90px;
  line-height: 96px;
  border-radius: 50px;
}
.item .feature_box_col_one {
  background: #efefef;
  color: #1ea3ae;
}
.processcard{
  border-radius: 12px !important;
  height: 240px !important;
}
.processcard:hover{
  background-color: #1ea3ae !important; 
  color: #fff !important;
}
.processcard:hover .numbercustom {
  padding: 10px 12px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.blogDescView img {
  width: 80%;
  margin-left: 8%;
}





/* media queries start here */
@media (max-width: 768px) {
  .Banner2-Bg {
    height: 500px;
    text-align: center;
  }

  .Banner-section {
    display: none;
  }

  .Banner3-Bg {
    height: 500px;
    text-align: center;
  }

  .Banner4-Bg {
    height: 500px;
    text-align: center;
    align-items: center;
  }

  .Banner5-Bg {
    height: 500px;
    text-align: center;
  }

  .Banner-section img {
    margin-top: 20px;
  }

  .CoverOverlay2 {
    height: 500px;
  }

  .ImageVision {
    height: 500px;
  }

  .OverlayVision {
    height: 500px;
  }

  .Banner-Bg {
    height: 500px;
    text-align: center;
  }

  .CoverOverlay {
    height: 500px;
  }

  .EmarketCard .card-text {
    height: 170px;
  }

  .Banneronboarding-Bg {
    height: 600px !important;
  }

  .CoverOverlay6 {
    height: 600px !important;
  }

  .bannersection {
    text-align: center;
    margin-top: 10px;
  }


}

@media (min-width: 576px) {
  .ImageStyle {
    align-items: center;
  }

  .weserve-section {
    margin: auto;
    margin-top: 15px;
  }

  .Banneronboarding-Bg {
    height: 625px !important;
  }

  .CoverOverlay6 {
    height: 625px !important;
  }
}

@media (max-width: 576px) {
  .Circle2 {
    margin-left: -100px;
  }

  .Circle3 .card {
    text-align: right;
  }

  .Banneronboarding-Bg {
    height: 1070px !important;
  }

  .CoverOverlay6 {
    height: 1070px !important;
  }

  .buttonpostion {
    z-index: 99;
    margin-left: 35% !important;
  }
}

@media (max-width: 1200px) {
  .CardsFlex {
    display: flex;
  }

  .Circle2 {
    margin-top: 10px !important;
  }

  .Circle3 {
    margin-top: 12px !important;
  }
}

@media (min-width: 575.98px) and (max-width: 1200px) {
  .contentMargin {
    padding-top: 170px;
  }

  .StatisticsBg .container {
    margin-top: 200px;
  }

}

@media (max-width: 576px) {
  .contentMargin {
    padding-top: 235px;
  }

  .EmarketCard {
    width: 300px;
  }
}

@media (max-width: 575.98px) {
  .ContactUsImg {
    display: none;
  }

}

@media (max-width: 992px) {
  .caseStudies {
    margin: 6% !important;
  }

  .MarkerPoint {
    margin-right: 0rem;
  }

  .Recentstories {
    height: 500px;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
  }

  .Recentstories::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }

  .Banneronboarding-Bg {
    height: 685px !important;
  }

  .CoverOverlay6 {
    height: 685px !important;
  }

  .dashboard-features img {
    height: 350px;
    width: 250px;
  }

  .dashboard-features {
    text-align: center;
  }

  .buttonpostion {
    margin-left: 18%;
  }
}

@media (max-width: 1200px) and (min-width: 991px) {
  .Banneronboarding-Bg {
    height: 742px !important;
  }

  .CoverOverlay6 {
    height: 742px !important;
  }

  .buttonpostion {
    margin-left: 19%;
  }
}

@media (max-width: 767px) {
  .Banneronboarding-Bg {
    height: 1070px !important;
  }

  .CoverOverlay6 {
    height: 1070px !important;
  }

  .blogcarddisplay {
    height: 500px;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
    padding-top: 20px;
  }

  .blogcarddisplay::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }

  .cardspace p,
  h6 {
    margin: 20px;
  }

  .buttonpostion {
    margin-left: 23%;
  }
}

@media (max-width: 367px) {
  .Banneronboarding-Bg {
    height: 1150px !important;
  }

  .CoverOverlay6 {
    height: 1150px !important;
  }

  .buttonpostion {
    margin-left: 25%;
    margin-top: 5rem !important;
  }
}

/* media queries ends here */